<script>
import { VueTyper } from "vue-typer";
import {
  ArrowUpIcon,
     FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    GithubIcon,
    YoutubeIcon,
    GitlabIcon,
    UserPlusIcon,
  MailIcon,
  UserIcon,
    MessageCircleIcon,
    BellIcon,
    ToolIcon,
    PhoneIcon,
    BookmarkIcon,
    ItalicIcon,
    GlobeIcon,
    GiftIcon,
    MapPinIcon,
  CameraIcon,
} from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";
import VueEasyLightbox from "vue-easy-lightbox";
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

/**
 * Index-personal component
 */
export default {
  data() {
    return {
      list: [
        {
          image: "images/personal/1.jpg",
          title: "Iphone mockup",
          type: "Branding",
        },
        {
          image: "images/personal/2.jpg",
          title: "Mockup Collection",
          type: "Mockup",
        },
        {
          image: "images/personal/3.jpg",
          title: "Abstract images",
          type: "Abstract",
        },
        {
          image: "images/personal/4.jpg",
          title: "Yellow bg with Books",
          type: "Books",
        },
        {
          image: "images/personal/5.jpg",
          title: "Company V-card",
          type: "V-card",
        },
        {
          image: "images/personal/6.jpg",
          title: "Mockup box with paints",
          type: "Photography",
        },
      ],
      lightboximgs: [
        "images/personal/1.jpg",
        "images/personal/2.jpg",
        "images/personal/3.jpg",
        "images/personal/4.jpg",
        "images/personal/5.jpg",
        "images/personal/6.jpg",
      ],
      visible: false,
      index: 0, // default: 0,
    };
  },
  components: {
    Navbar,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
    FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,
        GithubIcon,
        YoutubeIcon,
        GitlabIcon,
         UserPlusIcon,
    MailIcon,
    UserIcon,
    MessageCircleIcon,
    BellIcon,
    ToolIcon,
    PhoneIcon,
    BookmarkIcon,
    ItalicIcon,
    GlobeIcon,
    GiftIcon,
    MapPinIcon,
    CameraIcon,
    "vue-typer": VueTyper,
    MessageCircleIcon,
    VueEasyLightbox,
  },
  methods: {
    updateFilter(filterName) {
      this.filterCategory = filterName;
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section
      class="bg-home rtl-personal-hero bg-light d-flex align-items-center"
      style="background: url('images/personal/bg08.jpg') center center; Opacity:0.9;"
      id="home"
    >


      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-9">
<br><br><br><br><br><br><br>

            <div class="title-heading mt-4">

              <h2 class="display-4 text-primary font-weight-bold mb-3">
                
                Shape
the future
& define
what's possible
              </h2>
            
              <h5 class="para-desc text-muted">
              Come shape your business digital future with us. 
              </h5>
              <div class="mt-4 pt-2">
                <a
                  v-scroll-to="'#portfolio'"
                  class="btn btn-primary mt-2 mr-2 mouse-down"
                  ><i class="mdi mdi-camera"></i> View Portfolio</a
                >
                <a
                  v-scroll-to="'#contact'"
                  class="btn btn-outline-primary mt-2 mouse-down"
                  ><i class="mdi mdi-cloud-download"></i> Hire Me</a
                >
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
















      
    </section>
    <!--end section-->
    <!-- Hero End -->









    <!-- About Start -->
    <section class="section">







      <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card public-profile border-0 rounded shadow"  style="background: url('none') center center;">
                        <div class="card-body bg-soft-dark">
                            <div class="row align-items-center">
                                <div class="col-lg-2 col-md-3 text-md-left text-center">
                                    <img src="images/client/000.png" class="avatar avatar-large rounded-circle shadow d-block mx-auto"  alt="" >
                                </div>
                                <!--end col-->

                                <div class="col-lg-10 col-md-9">
                                    <div class="row align-items-end">
                                        <div class="col-md-7 text-md-left text-center mt-4 mt-sm-0">
                                                                          <img src="images/ym.png" alt="">
                                                 <h6 class="text-white font-weight-bold text-muted h6 mr-2">Co-Founder and Chief Strategist</h6>

Driving Growth for over 100+ brands since 2007

                                        </div>
                                        <!--end col-->
                                        <div class="col-md-5 text-md-right text-center">




           </div>
                                        <!--end col-->
                                    </div>
                                    <!--end row-->
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--ed container-->








      <div class="container">
        <div class="row justify-content-center">


  </div>
        <!--end row-->

        <div class="row align-items-center">
          <div class="col-12 mt-4 pt-2">
            <div class="">
              <b-tabs
                pills
                justified
                nav-class="d-inline-block border py-1 px-2 rounded-pill"
                nav-wrapper-class="text-center"
                align="center"
              >
                <b-tab
                  title-item-class="d-inline-block"
                  title-link-class="px-3 rounded-pill"
                >
                  <template v-slot:title> PROFILE </template>

     <br><br>
             
                  <div class="row">







             
                <div class="col-lg-4 col-md-6 col-12 d-lg-block d-none">
                    <div class="sidebar sticky-bar p-4 rounded shadow">







                        <div class="widget">
                             <h5 class="widget-title font-weight-bold"><img src="images/icon/return-on-investment1.png" class="avatar avatar-40x" alt=""> So far, Achievements with the Power of the Internet</h5>

                            <div class="row">
                                <div class="col-6 mt-4 pt-2">
                                    <router-link to="/account-works" class="accounts rounded d-block shadow text-center py-3">
                                        <span class="pro-icons h3 text-muted"><i class="uil uil-file"></i></span>
                                        <h6 class="title text-dark h6 my-0">Drive Traffic</h6>
                                         <p class="text-muted mb-0">50K+ Per Month</p>

                                    </router-link>
                                </div>
                                <!--end col-->



                                <div class="col-6 mt-4 pt-2">
                                    <router-link to="/account-messages" class="accounts rounded d-block shadow text-center py-3">
                                        <span class="pro-icons h3 text-muted"><i class="uil uil-envelope-star"></i></span>
                                        <h6 class="title text-dark h6 my-0">Project Complete</h6>
                                        <p class="text-muted mb-0">For 150+ Startup</p>
                                    </router-link>
                                </div>
                                <!--end col-->

                                <div class="col-6 mt-4 pt-2">
                                    <router-link to="/account-payments" class="accounts rounded d-block shadow text-center py-3">
                                        <span class="pro-icons h3 text-muted"><i class="uil uil-transaction"></i></span>
                                        <h6 class="title text-dark h6 my-0">Works with</h6>
                                        <p class="text-muted mb-0">50+ Individual</p>
                                    </router-link>
                                </div>
                                <!--end col-->

                                <div class="col-6 mt-4 pt-2">
                                    <router-link to="/account-setting" class="accounts rounded d-block shadow text-center py-3">
                                        <span class="pro-icons h3 text-muted"><i class="uil uil-setting"></i></span>
                                        <h6 class="title text-dark h6 my-0">Manage Lead</h6>
                                        <p class="text-muted mb-0">50+ With Karukaj</p>
                                    </router-link>
                                </div>
                                <!--end col-->
                                <div class="col-6 mt-4 pt-2">
                                    <router-link to="/account-messages" class="accounts rounded d-block shadow text-center py-3">
                                        <span class="pro-icons h3 text-muted"><i class="uil uil-envelope-star"></i></span>
                                        <h6 class="title text-dark h6 my-0">Build Link</h6>
                                        <p class="text-muted mb-0">More than 10K+</p>
                                    </router-link>
                                </div>
                                <!--end col-->



                                <div class="col-6 mt-4 pt-2">
                                    <router-link to="/account-works" class="accounts rounded d-block shadow text-center py-3">
                                        <span class="pro-icons h3 text-muted"><i class="uil uil-file"></i></span>
                                        <h6 class="title text-dark h6 my-0">Works with</h6>
                                         <p class="text-muted mb-0">100+ Companies</p>

                                    </router-link>
                                </div>
                                <!--end col-->

                            </div>
                            <!--end row-->




                        </div>












                        <div class="widget mt-4 pt-2">
                            <h5 class="widget-title font-weight-bold"><img src="images/icon/return-on-investment1.png" class="avatar avatar-40x" alt=""> Software Skills and Expertness</h5>
                            

                            <!--end process box-->
                            <div class="progress-box mt-4">
                                <h6 class="title text-muted">Laravel Development</h6>
                                <div class="progress">
                                    <div class="progress-bar position-relative bg-primary" style="width:65%;">
                                        <div class="progress-value d-block text-muted h6">65%</div>
                                    </div>
                                </div>                              
                            </div>
                            <!--end process box-->


                            <div class="progress-box mt-4">
                                <h6 class="title text-muted">Vue.js Development</h6>
                                <div class="progress">
                                    <div class="progress-bar position-relative bg-primary" style="width:85%;">
                                        <div class="progress-value d-block text-muted h6">85%</div>
                                    </div>
                                </div>                              
                            </div>
                            <!--end process box-->
                            <div class="progress-box mt-4">
                                <h6 class="title text-muted">WordPress Development</h6>
                                <div class="progress">
                                    <div class="progress-bar position-relative bg-primary" style="width:95%;">
                                        <div class="progress-value d-block text-muted h6">95%</div>
                                    </div>
                                </div>                              
                            </div>
                             <!--end process box-->

                            <div class="progress-box mt-4">
                                <h6 class="title text-muted">After Effect</h6>
                                <div class="progress">
                                    <div class="progress-bar position-relative bg-primary" style="width:85%;">
                                        <div class="progress-value d-block text-muted h6">85%</div>
                                    </div>
                                </div>                              
                            </div>
                            <!--end process box-->

                            <div class="progress-box mt-4">
                                <h6 class="title text-muted">Premier Pro</h6>
                                <div class="progress">
                                    <div class="progress-bar position-relative bg-primary" style="width:80%;">
                                        <div class="progress-value d-block text-muted h6">80%</div>
                                    </div>
                                </div>                              
                            </div>
                            <!--end process box-->

                                                        <div class="progress-box mt-4">
                                <h6 class="title text-muted">Digital Marketing</h6>
                                <div class="progress">
                                    <div class="progress-bar position-relative bg-primary" style="width:90%;">
                                        <div class="progress-value d-block text-muted h6">90%</div>
                                    </div>
                                </div>                              
                            </div>
                            <!--end process box-->
                            


 <br>   <br>  




                        </div>








                    </div>
                </div>
                <!--end col-->









                <div class="col-lg-8 col-12">

                    <div class="pb-4">

              <h5>
</h5>

              <h4 class="font-weight-bold mb-3">
               <img src="images/icon/career.png" class="avatar avatar-40x" alt=""> Focus (on)
                <vue-typer
                  :text="['Internet Marketer & Researcher', 'Serial Entrepreneur', 'Systems Expert & Inventor', 'Digital Security Resourcer']"
                ></vue-typer>
              </h4>
            

              <p class="text-muted mb-0">BeSight advising on the digital security provisions in livelihood, 
                he and his talented team has been re-introducing tech solutions for anyone's brand content-development strategies. 
 </p>
<br>









                <div class="col-12">
                          <h4 class="text-center font-weight-bold"><img src="images/icon/team-2.png" class="avatar avatar-40x" alt=""> Agency-Centric Processes</h4>

<p class="text-muted mb-0">                We live everywhere data lives. Here at Karukaj, he feel comfortable by knowing that the Karukaj Digital's solutions are being working well in strategically and carefully.


   The most game-changing companies, consumers, and professionals come to us for advanced digital development growth.
  </p>

<br>
<p class="text-muted mb-0">
   Today's data challenges 
   require creative exceptional skills. It’s You & Us. Together, we’re the next big thing in data for your company new heights.</p>


</div>







                        <div class="row">
                            <div class="col-md-6 mt-4">
                                <div class="mt-4">


  <div class="card work-process border-0 rounded shadow">
              <div class="card-body">
                <h5 class="title"> <i class="mdi mdi-chevron-double-right mdi-36px"></i> Idea </h5>
                <p class="text-muted para">
                  Karukaj has allowed me to focus on growing business!
                </p>
              </div>
            </div>


                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-md-6 mt-4 pt-2 pt-sm-0">
                   <div class="mt-4">


                              
  <div class="card work-process border-0 rounded shadow">
              <div class="card-body">
                <h5 class="title">  <i class="mdi mdi-check-all mdi-36px"></i> Final Approvement</h5>
                <p class="text-muted para">
                  Karukaj has allowed me to focus on growing business!
                </p>
              </div>
            </div>


</div>

                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->










                                            <ul class="list-inline mb-0 mt-3">
                                                <li class="list-inline-item mr-2"><a href="javascript:void(0)" class="text-muted" title="Instagram">
                                                        <facebook-icon class="fea icon-sm mr-2"></facebook-icon>Master's of Business Administration
                                                    </a></li>
                                                <li class="list-inline-item">
                                                    <router-link to="/account-setting" class="rounded text-muted" v-b-tooltip.hover title="Settings">
                                                        <linkedin-icon class="fea icon-sm fea-social mr-2"></linkedin-icon>
                                                        Linkedin Page
                                                    </router-link>
                                                </li>
                                        </ul>



         <p class="answer text-muted mb-0">
                                              
                                              </p> 




              <ul class="list-unstyled text-muted my-4">
                <li class="list-inline-item mr-lg-5 mr-4">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Organize your data
                </li>
                <li class="list-inline-item mr-lg-5 mr-4">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Work with any team
                </li>
                <li class="list-inline-item mr-lg-5 mr-4">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Business analytics
                </li>
                <li class="list-inline-item mr-lg-5 mr-4">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Always in sync
                </li>
                <li class="list-inline-item mr-lg-5 mr-4">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Embedded analytics
                </li>
              </ul>




            

                  </div>




                  <div class="pb-4">

                <div class="col-12 text-center">
                           

                        <h4 class="col-12 text-center font-weight-bold"><img src="images/icon/new-job.png" class="avatar avatar-40x" alt=""> Competence of Past Career</h4>
         <p class="text-muted mb-0">Apart from freelancing, he was engaged in a few local associations, i.e.,</p>
                </div>
                <!--end col-->




                        <div class="row">
                            <div class="col-md-6 mt-4">
                                <div class="mt-4">


                               <div class="media key-feature align-items-center p-3 rounded shadow mt-4">
                                    <img src="images/personal/bi.jpg" class="avatar avatar-50x" alt="">
                                    <div class="media-body content ml-3">
                                        <h4 class="title mb-0">Biswas IMPORTS</h4>
                                        <p class="text-muted mb-0">3 Years Experience</p>
                                        <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">CircleCi</a> @London, UK</p>
                                    </div>
                                </div>

                                <div class="media key-feature align-items-center p-3 rounded shadow mt-4">
                                    <img src="images/personal/sk.jpg" class="avatar avatar-50x" alt="">
                                    <div class="media-body content ml-3">
                                        <h4 class="title mb-0">Sadakalo Fashion House</h4>
                                        <p class="text-muted mb-0">2 Years Experience</p>
                                        <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">Codepen</a> @Washington D.C, USA</p>
                                    </div>
                                </div>


 
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-md-6 mt-4 pt-2 pt-sm-0">

                              
                                <div class="media key-feature align-items-center p-3 rounded shadow mt-4">
                                    <img src="images/personal/d3-1.jpg" class="avatar avatar-50x" alt="">
                                    <div class="media-body content ml-3">
                                        <h4 class="title mb-0">Dimension3 Architecture</h4>
                                        <p class="text-muted mb-0">3 Years Experience</p>
                                        <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">CircleCi</a> @London, UK</p>
                                    </div>
                                </div>


                                <div class="media key-feature align-items-center p-3 rounded shadow mt-4">
                                    <img src="images/personal/g9-1.jpg" class="avatar avatar-50x" alt="">
                                    <div class="media-body content ml-3">
                                        <h4 class="title mb-0">G9Vision IT</h4>
                                        <p class="text-muted mb-0">2 Years Experience</p>
                                        <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">Gitlab</a> @Perth, Australia</p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->


                            
                        </div>
                        <!--end row-->



 <br>   <br>

      <div class="container">

        <div class="row">
 <div class="col-12 text-center">
            <div class="mb-4 pb-2">
              <h4 class="mb-4"><img src="images/icon/idea-business.png" class="avatar avatar-40x" alt="">  Trusted by 10+ Companies Around the World</h4>

         <p class="text-muted mb-0">His endeavors are much acclaimed among all.</p>
    
            </div>
          </div>
          <!--end col-->

          <div class="col-12 mt-4 pt-2">
            <carousel
              id="customer-testi"
              ref="carousel"
              dir="ltr"
              :per-page="3"
              class="owl-carousel owl-theme"
              :autoplay="true"
              :loop="true"
            >
              <slide>
                <div
                  class="card customer-testi text-center border rounded mx-2"
                >
                  <div class="card-body">
                    <img
                      src="images/customer/logo88.png"
                      class="img-fluid avatar avatar-ex-sm mx-auto"
                      alt=""
                    />
                  </div>
                </div>
              </slide>
              <slide>
                <div
                  class="card customer-testi text-center border rounded mx-2"
                >
                  <div class="card-body">
                    <img
                      src="images/logo-dark2.png"
                      class="img-fluid avatar avatar-ex-sm mx-auto"
                      alt=""
                    />
                  </div>
                </div>
              </slide>
              <slide>
                <div
                  class="card customer-testi text-center border rounded mx-2"
                >
                  <div class="card-body">
                    <img
                      src="images/client/lenovo.svg"
                      class="img-fluid avatar avatar-ex-sm mx-auto"
                      alt=""
                    />
                  </div>
                </div>
              </slide>
              <slide>
                <div
                  class="card customer-testi text-center border rounded mx-2"
                >
                  <div class="card-body">
                    <img
                      src="images/client/paypal.svg"
                      class="img-fluid avatar avatar-ex-sm mx-auto"
                      alt=""
                    />
                  </div>
                </div>
              </slide>
              <slide>
                <div
                  class="card customer-testi text-center border rounded mx-2"
                >
                  <div class="card-body">
                    <img
                      src="images/client/shopify.svg"
                      class="img-fluid avatar avatar-ex-sm mx-auto"
                      alt=""
                    />
                  </div>
                </div>
              </slide>
              <slide>
                <div
                  class="card customer-testi text-center border rounded mx-2"
                >
                  <div class="card-body">
                    <img
                      src="images/client/spotify.svg"
                      class="img-fluid avatar avatar-ex-sm mx-auto"
                      alt=""
                    />
                  </div>
                </div>
              </slide>
            </carousel>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        
       <div class="col-12 text-center">
         <p class="text-muted mb-0">He also owns a few businesses and enterprises</p>
                </div>
      </div>
      <!--end container-->
 <br>
 <br>



                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="col-12 text-center font-weight-bold"> <img src="images/icon/return-on-investment1.png" class="avatar avatar-40x" alt=""> Education</h4>
              <p class="text-muted para-desc mx-auto mb-0">Our Certifications and Accolades Guarantee You’re In Safe Hands</p>

                        <div class="row">
                            <div class="col-md-6 mt-4">
                                <div class="mt-4">


                                <div class="align-items-center p-3 mt-4">
                                    <img src="images/personal/ideallawcollage.png" class="avatar avatar-100x" alt="">
                                    <div class="media-body content ml-3">
                                        <h5 class="mb-0">The Bachelor of Laws</h5>
                                        <p class="text-muted mb-0">3 Years Experience</p>
                                        <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">CircleCi</a> @London, UK</p>
                                    </div>
                                </div>

 
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-md-6 mt-4 pt-2 pt-sm-0">

                              
                                <div class="align-items-center p-3 mt-4">
                                    <img src="images/personal/smuct.jpg" class="avatar avatar-100x" alt="">
                                    <div class="media-body content ml-3">
                                        <h5 class="mb-0">Master's of Business Administration</h5>
                                        <p class="text-muted mb-0">3 Years Experience</p>
                                        <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">CircleCi</a> @London, UK</p>
                                    </div>
                                </div>


                            </div>
                            <!--end col-->

                            
                        </div>
                        <!--end row-->
<br>

                    </div>
                </div>
                <!--end col-->








            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4>Certified by</h4>





               <div class="customer-testi text-center">
      <div class="container">
        <div class="row justify-content-center">

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/client/google.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/client/lenovo.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/client/paypal.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/client/shopify.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>
          <!--end col-->

        </div>
        <!--end row-->
      </div>
      <!--end container-->
                </div>

<br>


                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

















<div class="col-12">


     <h5 class="widget-title">Some Useful Links</h5>


                                            <ul class="list-unstyled social-icon social mb-0 mt-4">
                                                <li class="list-inline-item"><a href="javascript:void(0)" class="rounded" v-b-tooltip.hover title="Add Friend">
                                                <user-plus-icon class="fea icon-sm fea-social"></user-plus-icon>
                                                </a></li>

                                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded" v-b-tooltip.hover title="Messages">
                                                <message-circle-icon class="fea icon-sm fea-social"></message-circle-icon>
                                                </a></li>

                                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded" v-b-tooltip.hover title="Notifications">
                                                <bell-icon class="fea icon-sm fea-social"></bell-icon>
                                                </a></li>
                               <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                                        <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                                        <instagram-icon class="fea icon-sm fea-social"></instagram-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                                        <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                                        <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                                        <github-icon class="fea icon-sm fea-social"></github-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                                        <youtube-icon class="fea icon-sm fea-social"></youtube-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                                        <gitlab-icon class="fea icon-sm fea-social"></gitlab-icon>
                                    </a></li>
                                 <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                                        <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                                        <instagram-icon class="fea icon-sm fea-social"></instagram-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                                        <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                                        <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                                    </a></li>
                    
                                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                                        <gitlab-icon class="fea icon-sm fea-social"></gitlab-icon>
                                    </a></li>
                                                <li class="list-inline-item ml-1">
                                                <router-link to="/account-setting" class="rounded" v-b-tooltip.hover title="Settings">
                                                <tool-icon class="fea icon-sm fea-social"></tool-icon>
                                                </router-link>

                                                </li>
                                            </ul>
                                            <!--end icon-->

</div>
                    </div>
                          
                </div>
                <!--end col-->

                  </div>
                  <!--end row-->


                </b-tab>
                <b-tab
                  title-item-class="d-inline-block"
                  title-link-class="px-3 rounded-pill"
                >
                  <template v-slot:title>
                    INSIGHTS
                    <span class="badge badge-pill badge-success">  </span>
                  </template>
                  <div class="row">


      <div class="container mt-100 mt-60" id="portfolio">
        <div class="row">
          <div class="col-12">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Work & Portfolio</h4>
              <p class="text-muted para-desc mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Karukaj</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-12 mt-4 pt-2"
            v-for="(item, index) of list"
            :key="index"
          >
            <div
              class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded"
            >
              <div class="card-body p-0">
                <img :src="item.image" class="img-fluid" alt="work-image" />
                <div class="overlay-work bg-dark"></div>
                <div class="content">
                  <h5 class="mb-0">
                    <router-link
                      to="/page-work-detail"
                      class="text-white title"
                      >{{ item.title }}</router-link
                    >
                  </h5>
                  <h6 class="text-light tag mb-0">{{ item.type }}</h6>
                </div>
                <div class="icons text-center">
                  <a
                    href="javascript: void(0);"
                    @click="() => showImg(index)"
                    class="text-primary work-icon bg-white d-inline-block rounded-pill mfp-image"
                  >
                    <camera-icon class="fea icon-sm"></camera-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <!--end col-->
          <vue-easy-lightbox
            :visible="visible"
            :index="index"
            :imgs="lightboximgs"
            @hide="visible = false"
          ></vue-easy-lightbox>
          <div class="col-12 mt-4 pt-2">
            <router-link to="/page-work-modern" class="btn btn-outline-primary"
              >See More <i class="mdi mdi-chevron-right"></i
            ></router-link>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->


                  </div>
                  <!--end row-->
                </b-tab>
              </b-tabs>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->






      
    </section>
    <!--end section-->
    <!-- About End -->


















<div class="uk-container-fluid">
  <div class="mid-cover uk-background-cover uk-height-large uk-panel imh-panel uk-flex uk-flex-left" 
style="background: url(../images/home/business-needs-overlay.svg) no-repeat, url(../images/home/imh-business-needs.jpg) no-repeat center center; background-size: cover;">
<div class="imh-wave wave-rotate imh-background-top imh-no-margin-top">
  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none slice" cclass="wave" viewBox="0 0 1440 100" aria-label="wave section divider">
  <path  style="background: #fff" class="white" d="M1393.4396,48.8095c-12.7704,2.3732-28.1398,4.9725-45.7695,7.7978 c-90.8607,13.6743-182.5126,20.794-274.3905,21.2461c-36.7285,0.452-75.4913-0.339-116.0623-2.3732 c-14.0133-0.6781-28.1397-1.4691-42.4921-2.4862c-85.4363-5.8766-170.4206-17.1777-254.3877-33.9033 c-47.9167-9.606-93.347-17.1777-136.0652-22.8282c-14.4654-1.9212-28.5918-3.6163-42.4921-5.1985 C300.7366-9.3912,170.6611,2.249,91.4404,16.0363C29.8494,26.9984-0.8896,39.2036-0.8896,39.2036v55.5257v11.2637v0.113v323.8734 H1440V106.106v-1.4007V39.2036C1440,39.2036,1423.6134,43.3849,1393.4396,48.8095z"></path></svg>
  </div>
  </div>
  </div>








      <div class="container">


        <div class="row">
          <div class="col-12 mt-4 pt-2">
                      <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
                          <h4 class="title font-weight-bold mb-4"><img src="images/icon/return-on-investment1.png" class="avatar avatar-40x" alt=""> Sponsored By</h4>
            </div>
          </div>

            <carousel
              id="customer-testi"
              ref="carousel"
              dir="ltr"
              :per-page="1"
              class="owl-carousel owl-theme"
              :autoplay="true"
              :loop="true"
            >
              <slide>
        <div class="customer-testi text-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/associates/evanto-bd-min.png"
              class="avatar avatar-kd-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/associates/fiverrbd-min.png"
              class="avatar avatar-kd-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/associates/up-workbd-min.png"
              class="avatar avatar-kd-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/associates/godaddynew-min.png"
              class="avatar avatar-kd-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/associates/free-hostgator-min.png"
              class="avatar avatar-kd-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/associates/flippabd-min.png"
              class="avatar avatar-kd-sm"
              alt=""
            />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
                </div>
                <!--end customer testi-->
              </slide>
              <slide>
                              <div class="customer-testi text-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/associates/up-workbd-min.png"
              class="avatar avatar-kd-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/associates/youtube-min.png"
              class="avatar avatar-kd-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/associates/godaddybdd-min.png"
              class="avatar avatar-kd-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/client/paypal.svg"
              class="avatar avatar-kd-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/client/shopify.svg"
              class="avatar avatar-kd-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center py-4">
            <img
              src="images/client/spotify.svg"
              class="avatar avatar-kd-sm"
              alt=""
            />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
                </div>
                <!--end customer testi-->
              </slide>
           
            </carousel>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->









    <!-- Start -->
    <div class="container-fluid px-0">
      <div class="py-5 bg-footer">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-7">
              <div class="section-title">
                <div class="media">
                  <i
                    class="uil uil-image-check display-4 text-white title-dark"
                  ></i>
                  <div class="media-body ml-md-4 ml-3">
                    <h4 class="font-weight-bold text-light title-dark mb-1">
                      Start a Meeting
                    </h4>
                    <p class="text-white-50 mb-0">
                      Get your issue resolved without wait.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-5 mt-4 mt-sm-0">
              <div class="text-md-right ml-5 ml-sm-0">
                <a
                  href="tel:+8801714641541"
                  class="btn btn-primary mr-2 mr-lg-2 mr-md-0 my-2"
                  data-toggle="modal"
                  data-target="#productview"
                  ><i class="uil uil-file-alt"></i> Give us a ring </a
                >
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
        
      </div>
      <!--end div-->
    </div>
    <!--end container-->
    <!-- End -->


    <footer class="footer footer-bar">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-left">
              <p class="mb-0">
                © {{ new Date().getFullYear() }} Karukaj. Design with
                <i class="mdi mdi-heart text-danger"></i> by
                <a
                  href="https://karukaj.net"
                  target="_blank"
                  class="text-reset"
                  >Karukaj DIGITAL</a
                >.
              </p>
            </div>
          </div>
          <!--end col-->

          <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <ul class="list-unstyled text-sm-right mb-0">
              <li class="list-inline-item">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/american-ex.png"
                    class="avatar avatar-ex-sm"
                    title="American Express"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/discover.png"
                    class="avatar avatar-ex-sm"
                    title="Discover"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/master-card.png"
                    class="avatar avatar-ex-sm"
                    title="Master Card"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/paypal.png"
                    class="avatar avatar-ex-sm"
                    title="Paypal"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/visa.png"
                    class="avatar avatar-ex-sm"
                    title="Visa"
                    alt=""
                /></a>
              </li>
            </ul>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

    </footer>
    <!--end footer-->
    <!-- Footer End -->
  
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
